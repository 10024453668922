import Keycloak from 'keycloak-js';
import store from '../store';

class KeycloakService {
  constructor() {
    this.kc = new Keycloak({
      url: process.env.VUE_APP_KEYCLOAK_URL,
      realm: process.env.VUE_APP_KEYCLOAK_REALM,
      clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID
    });
  }

  async initKeycloak() {
    return await this.kc.init({
      onLoad: 'check-sso',
      responseMode: 'query'
      
    }).then((auth) => {
      if (!auth) {
        // this.redirectToLogin();
        this.doLogin();
        return false;
      }
      return true;
    }).catch(() => {
      console.error("Authentication Failure");
      return false;
    });
  }

  doLogin() {
    return this.kc.login();
  }

  doLogout() {
    const postLogoutRedirectUri = process.env.VUE_APP_FRONTEND_URL + "login";
    console.log("Post Logout Redirect URI:", postLogoutRedirectUri);
    // Check if user is authenticated
    if (this.kc.authenticated) {
      return this.kc.logout({ redirectUri: postLogoutRedirectUri });
    } else {
      // Construct Keycloak logout URL
      const keycloakLogoutUrl = `${process.env.VUE_APP_KEYCLOAK_URL}realms/${process.env.VUE_APP_KEYCLOAK_REALM}/protocol/openid-connect/logout` +
        `?client_id=${process.env.VUE_APP_KEYCLOAK_CLIENT_ID}&post_logout_redirect_uri=${encodeURIComponent(postLogoutRedirectUri)}`;
       
  
      // Redirect to Keycloak logout URL
      window.location.href = keycloakLogoutUrl;
    }
  }

  getToken() {
    return this.kc.token;
  }

  getUserInfo(userProperty) {
    return this.kc.tokenParsed?.[userProperty] || null;
  }

  getIsAuthenticated() {
    return this.kc.authenticated;
  }

  refreshAccessToken(sec) {
    return this.kc.updateToken(sec);
  }

  async redirectToLogin() {


    // https://qa.vgos.org/realms/ampersand-internal-qa/protocol/openid-connect/auth?client_id=test&redirect_uri=https%3A%2F%2Fsmart-staff-selection-dev.web.app%2F%23%2Fvgos-internal&state=a0aa8103-8a9d-4d7b-8534-efd4de0afeb5&response_mode=query&response_type=code&scope=openid&nonce=68a60904-41fb-457d-942c-2cb76f5edafe&code_challenge=RsHAQcSxv9YMesvrkJRHFQWlhA7ozsuQe5kg160Iqh4&code_challenge_method=S256
    const redirectUri = process.env.VUE_APP_FRONTEND_URL + "vgos-internal";
    const codeVerifier = this.generateRandomString();
     // Function to generate a random string

    console.log('Setting codeVerifier in sessionStorage:', codeVerifier);
    store.dispatch('setCodeVerifier', codeVerifier)
   
    // sessionStorage.setItem('pkce_code_verifier', codeVerifier); // Save for later use in backend
    console.log('code verifier in login',codeVerifier)
    const codeChallenge = await this.generateCodeChallenge(codeVerifier); // 

    // const keycloakLoginUrl = `${process.env.VUE_APP_KEYCLOAK_URL}/realms/${process.env.VUE_APP_KEYCLOAK_REALM}/protocol/openid-connect/auth` +
    // `?client_id=${process.env.VUE_APP_KEYCLOAK_CLIENT_ID}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code`;
    
    const keycloakLoginUrl = `${process.env.VUE_APP_KEYCLOAK_URL}/realms/${process.env.VUE_APP_KEYCLOAK_REALM}/protocol/openid-connect/auth` +
    `?client_id=${encodeURIComponent(process.env.VUE_APP_KEYCLOAK_CLIENT_ID)}` +
    `&redirect_uri=${encodeURIComponent(redirectUri)}` +
    `&response_type=code` +
    `&scope=openid` +
    `&code_challenge=${encodeURIComponent(codeChallenge)}` +
    `&code_challenge_method=S256`; 
    
    window.location.href = keycloakLoginUrl;
  }

  generateRandomString(length = 43) {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    let text = '';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
  
  async generateCodeChallenge(codeVerifier) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await crypto.subtle.digest('SHA-256', data);
    return btoa(String.fromCharCode(...new Uint8Array(digest)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  // Function to generate code verifier and code challenge
  
  
}

const keycloakService = new KeycloakService();
export default keycloakService;