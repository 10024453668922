import axios from 'axios'
import AuthService from '@/services/AuthService';
const instance = axios.create({
    baseURL: process.env.VUE_APP_USERS_API_URL
});

export default {
    getUserInfo: async function () {
        const userId =  AuthService.getUserId()
        console.log("userId*****",userId)
        try {
            const response = await instance.get('/getUserInfo?userId='+userId ,{
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    
}